"use client";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";

export default function Anim() {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();

  const componentRef = useRef(null);
  const textContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [shouldMoveToNextSection, setShouldMoveToNextSection] = useState(false);

  const textItems = [
    {
      title: t("homePage.32"),
      text: t("homePage.33"),
    },
    {
      title: t("homePage.40"),
      text: t("homePage.41"),
    },
    {
      title: t("homePage.42"),
      text: t("homePage.43"),
    },
    {
      title: t("homePage.42"),
      text: t("homePage.43"),
    },
  ];

  useEffect(() => {
    const handleScroll = (e) => {
      if (isScrolling) return;
      e.preventDefault();

      setIsScrolling(true);
      const delta = e.deltaY;

      if (delta > 0) {
        // Scrolling down
        if (currentIndex < textItems.length - 1) {
          setCurrentIndex((prev) => prev + 1);
        } else if (
          currentIndex === textItems.length - 1 &&
          !shouldMoveToNextSection
        ) {
          setShouldMoveToNextSection(true);
          // Find the next section and scroll to it
          const nextSection = componentRef.current?.nextElementSibling;
          if (nextSection) {
            nextSection.scrollIntoView({ behavior: "smooth" });
          }
        }
      } else if (delta < 0) {
        // Scrolling up
        if (currentIndex > 0) {
          setCurrentIndex((prev) => prev - 1);
        }
      }

      setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    const component = componentRef.current;
    if (component) {
      component.addEventListener("wheel", handleScroll, { passive: false });
    }

    return () => {
      if (component) {
        component.removeEventListener("wheel", handleScroll);
      }
    };
  }, [currentIndex, isScrolling, shouldMoveToNextSection]);

  return (
    <div ref={componentRef} className="relative h-[816px] overflow-hidden ">
      {/* Left Side: Fixed Background Image */}
      <div
        className="absolute top-0 left-0 w-[56%] h-full bg-cover bg-center bg-no-repeat cursor-default"
        style={{ backgroundImage: 'url("/images/gif/homegif.gif")' }}
      >
        {/* First SVG - Shifted left closer to text and lowered */}
        <div className="absolute top-[13px] right-[5px]">
          <img
            src="/images/gif/ovalFull1.png"
            alt="background"
            style={{
              width: "457px",
              height: "500px",
              flexShrink: 0,
              opacity: 0.7,
              borderRadius: "50%",
            }}
          />
        </div>

        {/* Bottom Section with Swapped SVGs */}
        <div className="absolute bottom-[-30px] left-0 flex justify-between w-full px-8">
          {/* Bottom Left SVG */}
          <div className="absolute left-[-30px] bottom-6">
            <img
              src="/images/gif/ovalNew2.png"
              alt="background"
              style={{
                width: "600px",
                height: "492px",
                flexShrink: 0,
                opacity: 0.7,
              }}
            />
          </div>
          {/* Bottom Right SVG */}
          <div className="absolute right-0 bottom-0">
            <img
              src="/images/gif/ovalFull3.png"
              alt="background"
              style={{
                width: "223px",
                height: "228px",
                flexShrink: 0,
                opacity: 0.7,
                borderRadius: "275px",
              }}
            />
          </div>
        </div>

        {/* Text Content */}
        <h1 className="absolute top-[44px] left-14 md:left-24 flex flex-col">
          <span
            className="font-montserrat text-[64px] inline-block text-white font-bold leading-[96px]"
            style={{
              fontWeight: 700,
              fontFamily: "Montserrat",
            }}
          >
            {t("homePage.30")}
          </span>
          <span
            className="inline-block text-white font-montserrat text-[42px] md:text-[48px] font-bold leading-[60px]"
            style={{
              fontWeight: 700,
              fontFamily: "Montserrat",
            }}
          >
            {t("homePage.31")}
          </span>
        </h1>
      </div>

      {/* Right Side: Scrollable Text Sections */}
      <div className="absolute top-0 right-0 w-[44%] h-full overflow-hidden">
        <div
          ref={textContainerRef}
          className="absolute top-0 left-0 w-full transition-transform duration-500 ease-out p-8"
          style={{ transform: `translateY(-${currentIndex * 816}px)` }}
        >
          <div
            className={` h-[816px] flex flex-col justify-start text-[#38466D] bg-white font-montserrat`}
            style={{
              color: "#38466D",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "Montserrat",
              fontStyle: "normal",
            }}
          >
            <h2 className="text-[24px] font-bold mb-4 leading-[50px]"></h2>
            <p className="text-[20px] leading-8 font-normal"></p>
          </div>
          {textItems.map((item, index) => (
            <div
              key={index}
              className={`${
                index === 2 ? "mb-[816px]" : "mb-0"
              } h-[816px] flex flex-col justify-start text-[#38466D] bg-white font-montserrat`}
              style={{
                color: "#38466D",
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: "Montserrat",
                fontStyle: "normal",
              }}
            >
              <h2 className="text-[28px] font-bold mb-4 leading-[50px]">
                {item.title}
              </h2>
              <p className="text-[20px] leading-8 font-normal">{item.text}</p>
            </div>
          ))}
          <div className="h-[800px] flex flex-col justify-end text-[#38466D] bg-white font-montserrat">
            {" "}
          </div>
        </div>
      </div>
    </div>
  );
}
