import React, { useState } from "react";
import { motion } from "framer-motion";

export function TestimonialCard(props) {
  const [variant, setVariant] = useState("variant1");

  const variants = {
    variant1: { opacity: 0, x: 50 },
    variant2: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.2, ease: "easeOut" },
    },
    variant3: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.2, ease: "easeOut" },
    },
  };

  const handleMouseEnter = () => {
    setVariant("variant2");
  };

  const handleMouseLeave = () => {
    setVariant("variant3");
  };

  return (
    <div
      className="p-6 bg-white"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex items-start gap-20">
        <div
          className="relative"
          style={{
            borderRadius: "50%",
            width: "200px",
            height: "200px",
            background:
              "linear-gradient(287deg, #FFFBFE 2.09%, #ECD7F3 99.85%, #9364A8 73.68%, #9364A8 98.83%)",
          }}
        >
          <AvatarSvg
            imageSrc={props.item.image}
            className="rounded-full"
            style={{
              borderRadius: "50%",
              width: "200px",
              height: "200px",
              filter: "opacity(0.95)",
            }}
          />
        </div>

        {/* Text Content */}
        <motion.div
          className="flex-1 mt-2"
          initial="variant1"
          animate={variant}
          variants={variants}
        >
          <blockquote className="text-[#313033] text-[36px] font-montserrat">
            {props.item.text}
          </blockquote>
          <div>
            <h4 className="font-medium text-[#0F172E] text-[32px] font-MontserratMedium pt-[46px]">
              {props.item.author}
            </h4>
            <p className="text-[24px] text-[#38466D] font-montserrat">
              {props.item.title}
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

const AvatarSvg = ({ imageSrc }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    style={{
      borderRadius: "50%",
      overflow: "hidden",
    }}
  >
    {/* Gradient Circle */}
    <circle
      cx="100"
      cy="100"
      r="100"
      fill="url(#paint0_linear_1776_4418)"
      style={{
        mixBlendMode: "multiply",
        opacity: 0.35,
      }}
    />
    {/* Image with Clip Path */}
    <image
      href={imageSrc}
      x="0"
      y="0"
      height="200"
      width="200"
      clipPath="url(#clipCircle)"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    {/* Definitions */}
    <defs>
      {/* Gradient Definition */}
      <linearGradient
        id="paint0_linear_1776_4418"
        x1="197.282"
        y1="191"
        x2="-33.6142"
        y2="121.099"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFFBFE" />
        <stop offset="0.38" stopColor="#ECD7F3" />
        <stop offset="0.74" stopColor="#9364A8" />
        <stop offset="1" stopColor="#9364A8" />
      </linearGradient>
      {/* Clip Path for Circular Image */}
      <clipPath id="clipCircle">
        <circle cx="100" cy="100" r="100" />
      </clipPath>
    </defs>
  </svg>
);
